@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-5deg);
    }
    20% {
        transform: rotate(5deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    60% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(-5deg);
    }
    80% {
        transform: rotate(5deg);
    }
    90% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

.item-shake {
    width: 125%;
    height: 125%;
    animation-name: shake;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}