h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
}

@font-face {
    font-family: 'Minecraft';
    src: url(assets/Minecraft.woff);
}

body {
    background: #eee;
    padding: 10px;
    color: #404040;
}
